import { createContext } from "react";
import { useAtom } from "jotai";
import { authAtom } from "./authAtom";
import { Toast } from "../components/Toast";
import api from "../utils/api";
import { LOGIN_URL } from "../utils/endpoints";
import jwtDecode from "jwt-decode";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useAtom(authAtom);
  const { addToast } = Toast();

  const login = async (data) => {
    try {
      const response = await api.post(LOGIN_URL, {
        email: data.email,
        password: data.password,
      });

      const { token, provinceId, countyId, schoolId, roleName } = response.data;

      if (token) {
        localStorage.setItem("@retfoptoken", token);

        const decodedToken = jwtDecode(token);

        const user = {
          id: decodedToken.nameid,
          email: decodedToken.email,
          name: decodedToken.unique_name,
          permissions: decodedToken.role,
          provinceId,
          countyId,
          schoolId,
        };

        setAuth({
          isAuthenticated: true,
          isSchool: Boolean(schoolId),
          user,
          roleName,
        });

        return user;
      }
    } catch (error) {
      let errors = error.response.data.errors;
      for (let err in errors) {
        addToast({ title: errors[err], status: "error" });
      }
      return null;
    }
  };

  const logout = () => {
    localStorage.removeItem("@retfoptoken");
    setAuth({
      isAuthenticated: false,
      isSchool: null,
      user: null,
      roleName: null,
    });
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
