import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import { formStyles, selectStyles } from "../../../utils/styles";
import Label from "../../../components/Label";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { NO_OPTIONS_MESSAGE, REQUIRED_FIELD } from "../../../utils/constants";
import CustomDatePicker from "../../../components/CustomDatePicker";
import { useEffect, useState } from "react";
import { getGender } from "../../../services/getGender";
import { getNationality } from "../../../services/getNationality";
import { getPedagogicalTraining } from "../../../services/getPedagogicalTraining";
import { Toast } from "../../../components/Toast";
import { getAcademicQualification } from "../../../services/getAcademicQualification";
import { getTrainingArea } from "../../../services/getTrainingArea";
import EditDocument from "../../Student/components/EditDocument";

function TabTeacherInformation({
  isDisabled,
  register,
  errors,
  control,
  watch,
  setValue,
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [genderOptions, setGenderOptions] = useState([]);
  const [nationalityOptions, setNationalityOptions] = useState([]);
  const [academicQualificationOptions, setAcademicQualificationOptions] =
    useState([]);
  const [trainingAreaOptions, setTrainingAreaOptions] = useState([]);
  const [pedagogicalTrainingOptions, setPedagogicalTrainingOptions] = useState(
    []
  );
  const { addToast } = Toast();

  const typeDocument = watch("typeDocument");
  const nationality = watch("nationality");
  const academicQualification = watch("academicQualification");
  const trainingArea = watch("trainingArea");
  const documentNumber = watch("documentNumber");

  async function getGenderOptions() {
    getGender()
      .then((res) => {
        setGenderOptions(res);
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getNationalityOptions() {
    getNationality()
      .then((res) => {
        setNationalityOptions(res);
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getAcademicQualificationOptions() {
    getAcademicQualification()
      .then((res) => {
        setAcademicQualificationOptions(res);
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getTrainingAreaOptions() {
    getTrainingArea()
      .then((res) => {
        setTrainingAreaOptions(res);
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getPedagogicalTrainingOptions() {
    getPedagogicalTraining()
      .then((res) => {
        setPedagogicalTrainingOptions(res);
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  useEffect(() => {
    getGenderOptions();
    getNationalityOptions();
    getAcademicQualificationOptions();
    getTrainingAreaOptions();
    getPedagogicalTrainingOptions();
    setIsLoaded(true);
  }, []);

  return (
    isLoaded && (
      <Box>
        <Heading as="h2" size="md" textTransform="uppercase" mb={2}>
          Informações do Docente
        </Heading>
        <Box sx={formStyles}>
          <Stack spacing={5}>
            <Flex gap={3} alignItems="end">
              <FormControl>
                <Label title="Tipo de documento de identificação" isRequired />
                <Input {...register("typeDocumentName")} isDisabled={true} />
              </FormControl>
              <Flex justifyContent="end">
                <EditDocument
                  documentType={typeDocument}
                  documentNumber={documentNumber}
                  entity="docente"
                  setValue={setValue}
                  isDisabled={isDisabled}
                />
              </Flex>
            </Flex>
            <FormControl>
              <Label title="Nº de documento de identificação" isRequired />
              <Input {...register("documentNumber")} isDisabled={true} />
            </FormControl>
            <FormControl isInvalid={!!errors.name}>
              <Label title="Nome" isRequired />
              <Input {...register("name")} isDisabled={isDisabled} />
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.agentNumber}>
              <Label
                title="Nº de agente"
                helper={
                  "(Preencher apenas no caso do Docentes das Escolas Pública e Público-Privadas)"
                }
              />
              <Input {...register("agentNumber")} isDisabled={isDisabled} />
              <FormErrorMessage>
                {errors.agentNumber && errors.agentNumber.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.gender}>
              <Label title="Género" isRequired />
              <Controller
                control={control}
                name="gender"
                render={({ field: { onChange, value } }) => (
                  <RadioGroup value={value} onChange={onChange}>
                    <Stack>
                      {genderOptions.map((type) => (
                        <Radio
                          key={type.value}
                          value={type.value?.toString()}
                          isDisabled={isDisabled}
                        >
                          {type.label}
                        </Radio>
                      ))}
                    </Stack>
                  </RadioGroup>
                )}
              />
              <FormErrorMessage>
                {errors.gender && REQUIRED_FIELD}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.nationality}>
              <Label title="Nacionalidade" isRequired />
              <Controller
                control={control}
                name="nationality"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder="Seleccionar"
                    options={nationalityOptions}
                    defaultValue={nationality}
                    isDisabled={isDisabled}
                    isSearchable={true}
                    isClearable={false}
                    onChange={onChange}
                    value={value || ""}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.nationality && <>{REQUIRED_FIELD}</>}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.birthDate}>
              <Label title="Data de nascimento" isRequired />
              <Controller
                control={control}
                name="birthDate"
                render={({ field }) => (
                  <CustomDatePicker
                    selected={field.value}
                    placeholder="Seleccionar"
                    onChange={(date) => field.onChange(date)}
                    disabled={isDisabled}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.birthDate ? (
                  errors.birthDate.type === "invalid_type" ? (
                    <>{REQUIRED_FIELD}</>
                  ) : (
                    <>{errors.birthDate.message}</>
                  )
                ) : (
                  <></>
                )}
              </FormErrorMessage>
              <FormHelperText>Formato: dd/mm/aaaa</FormHelperText>
            </FormControl>
            <Heading fontSize="2xl">Habilitações</Heading>
            <FormControl isInvalid={!!errors.academicQualification}>
              <Label title="Habilitação Académica" isRequired />
              <Controller
                control={control}
                name="academicQualification"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder="Seleccionar"
                    options={academicQualificationOptions}
                    defaultValue={academicQualification}
                    isDisabled={isDisabled}
                    isSearchable={true}
                    isClearable={false}
                    onChange={onChange}
                    value={value || ""}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.academicQualification && <>{REQUIRED_FIELD}</>}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.trainingArea}>
              <Label title="Área de Formação" isRequired />
              <Controller
                control={control}
                name="trainingArea"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder="Seleccionar"
                    options={trainingAreaOptions}
                    defaultValue={trainingArea}
                    isDisabled={isDisabled}
                    isSearchable={true}
                    isClearable={false}
                    onChange={onChange}
                    value={value || ""}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.trainingArea && <>{REQUIRED_FIELD}</>}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.pedagogicalTraining}>
              <Label title="Formação Pedagógica" isRequired />
              <Controller
                control={control}
                name="pedagogicalTraining"
                render={({ field: { onChange, value } }) => (
                  <RadioGroup value={value} onChange={onChange}>
                    <Stack>
                      {pedagogicalTrainingOptions.map((type) => (
                        <Radio
                          key={type.value}
                          value={type.value?.toString()}
                          isDisabled={isDisabled}
                        >
                          {type.label}
                        </Radio>
                      ))}
                    </Stack>
                  </RadioGroup>
                )}
              />
              <FormErrorMessage>
                {errors.pedagogicalTraining && REQUIRED_FIELD}
              </FormErrorMessage>
            </FormControl>
          </Stack>
        </Box>
      </Box>
    )
  );
}

export default TabTeacherInformation;
