import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { REPORTS_SUMMARY } from "../../utils/constants";
import TabGeneralData from "./components/TabGeneralData";
import TabStudents from "./components/TabStudents";
import TabSchools from "./components/TabSchools";
import TabTeachers from "./components/TabTeachers";
import { Link } from "react-router-dom";
import { MdArrowOutward } from "react-icons/md";

function Reports() {
  return (
    <Stack spacing={5}>
      <Box>
        <Heading as="h1" mb="10px">
          Estatísticas
        </Heading>

        <Box w="lg">
          <Text>{REPORTS_SUMMARY}</Text>
        </Box>
      </Box>

      <Tabs>
        <TabList>
          <Tab>Dados Gerais</Tab>
          <Tab>Alunos</Tab>
          <Tab>Escolas</Tab>
          <Tab>Docentes</Tab>
        </TabList>

        <TabPanels>
          <TabPanel p={0} pt="20px">
            <TabGeneralData />
          </TabPanel>
          <TabPanel p={0} pt="20px">
            <TabStudents />
          </TabPanel>
          <TabPanel p={0} pt="20px">
            <TabSchools />
          </TabPanel>
          <TabPanel p={0} pt="20px">
            <TabTeachers />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Flex justifyContent="end" mt={3}>
        <Link to="/docs/manual-estatisticas-sietp.pdf" target="_blank">
          <Button variant="secondary" rightIcon={<MdArrowOutward />}>
            Consultar Manual de Apoio
          </Button>
        </Link>
      </Flex>
    </Stack>
  );
}

export default Reports;
