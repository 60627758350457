import {
  Box,
  Button,
  Checkbox,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Label from "../../../components/Label";
import { selectStyles } from "../../../utils/styles";
import {
  HAS_LEGAL_EQUIVALENCE,
  HAS_LEGAL_PROOF,
  NO_OPTIONS_MESSAGE,
  REQUIRED_FIELD,
} from "../../../utils/constants";
import Select from "react-select";
import { useEffect, useState } from "react";
import { Toast } from "../../../components/Toast";
import {
  ACADEMIC_YEAR_URL,
  BOND_RESULT_URL,
  CLASS_LETTER_URL,
  CLASS_MODALITY_URL,
  CLASS_SHIFT_URL,
  CLASS_URL,
  COURSE_URL,
  ENROLLMENT_STATUS_URL,
  SCHOOL_URL,
} from "../../../utils/endpoints";
import api from "../../../utils/api";

function EditEnrollmentModal({
  id,
  index,
  schoolBondRequests,
  setSchoolBondRequests,
  schoolBondRequestsData,
  setSchoolBondRequestsData,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [academicYear, setAcademicYear] = useState();
  const [school, setSchool] = useState();
  const [course, setCourse] = useState();
  const [studentClass, setStudentClass] = useState();
  const [classModality, setClassModality] = useState();
  const [classShift, setClassShift] = useState();
  const [classLetter, setClassLetter] = useState();
  const [classFullName, setClassFullName] = useState();
  const [studentEnrollmentStatus, setStudentEnrollmentStatus] = useState();
  const [schoolBondResult, setSchoolBondResult] = useState();
  const [courseCompletionAverage, setCourseCompletionAverage] = useState();
  const [academicYearOptions, setAcademicYearOptions] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [classOptions, setClassOptions] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [classModalityOptions, setClassModalityOptions] = useState([]);
  const [classShiftOptions, setClassShiftOptions] = useState([]);
  const [classLetterOptions, setClassLetterOptions] = useState([]);
  const [studentEnrollmentStatusOptions, setStudentEnrollmentStatusOptions] =
    useState([]);
  const [schoolBondResultOptions, setSchoolBondResultOptions] = useState([]);
  const [hasLegalEquivalence, setHasLegalEquivalence] = useState(false);
  const [hasLegalProof, setHasLegalProof] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [schoolBondResultIsDisabled, setSchoolBondResultIsDisabled] =
    useState(false);
  const { addToast } = Toast();

  async function getAcademicYears() {
    api
      .get(ACADEMIC_YEAR_URL)
      .then((res) => {
        const data = res.data.result;
        setAcademicYearOptions(
          data
            .filter((i) => i.isActive)
            .map((i) => ({
              value: i.id,
              label: i.year,
            }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getSchools() {
    api
      .get(`${SCHOOL_URL}?isValidated=true&isActive=true`)
      .then((res) => {
        const data = res.data.result;
        setSchoolOptions(
          data.map((i) => ({
            value: i.id,
            label: i.name,
          }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getCourses() {
    api
      .get(
        `${COURSE_URL}?isValidated=true&isActive=true&schoolId=${school.value}&AcademicYearId=${academicYear.value}`
      )
      .then((res) => {
        const data = res.data.result;
        setCourseOptions(
          data.map((i) => ({
            value: i.id,
            label: i.name,
            className: i.className,
            courseTechnicalName: i.courseTechnical?.name,
            courseTechnicalId: i.courseTechnical?.id,
            educationalSubsystemName: i.educationalSubsystem.name,
            educationalSubsystemId: i.educationalSubsystem.id,
            educationalCycleName: i.educationalCycle.name,
            educationalCycleId: i.educationalCycle.id,
          }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getClasses() {
    api
      .get(
        `${CLASS_URL}?OnlyActive=true&ParentId=${course?.educationalCycleId}`
      )
      .then((res) => {
        const data = res.data;
        setClassOptions(
          data.map((i) => ({
            value: i.id,
            label: i.name,
            code: i.code,
            previousClassCode: i.previousClassCode,
          }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getClassModalities() {
    api
      .get(`${CLASS_MODALITY_URL}?OnlyActive=true`)
      .then((res) => {
        const data = res.data;
        setClassModalityOptions(
          data.map((i) => ({
            value: i.id,
            label: i.name,
          }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getClassShifts() {
    api
      .get(`${CLASS_SHIFT_URL}?OnlyActive=true&ParentId=${classModality.value}`)
      .then((res) => {
        const data = res.data;
        setClassShiftOptions(
          data.map((i) => ({
            value: i.id,
            label: i.name,
          }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getClassLetters() {
    api
      .get(`${CLASS_LETTER_URL}?OnlyActive=true`)
      .then((res) => {
        const data = res.data;
        setClassLetterOptions(
          data.map((i) => ({
            value: i.id,
            label: i.name,
          }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getStudentEnrollmentStatus() {
    api
      .get(`${ENROLLMENT_STATUS_URL}?OnlyActive=true`)
      .then((res) => {
        const data = res.data;
        setStudentEnrollmentStatusOptions(
          data.map((i) => ({
            value: i.id,
            label: i.name,
          }))
        );
        setStudentEnrollmentStatus(
          data.map((i) => ({
            value: i.id,
            label: i.name,
          }))[0]
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  async function getSchoolBondResults() {
    api
      .get(`${BOND_RESULT_URL}?OnlyActive=true`)
      .then((res) => {
        const data = res.data;
        setSchoolBondResultOptions(
          data.map((i) => ({
            value: i.id,
            label: i.name,
          }))
        );
      })
      .catch((error) => {
        addToast({ title: error.message, status: "error" });
      });
  }

  const onChangeAcademicYear = (value) => {
    setAcademicYear(value);
  };

  const onChangeSchool = (value) => {
    setSchool(value);
  };

  const onChangeCourse = (value) => {
    setCourse(value);
  };

  const onChangeClass = (value) => {
    setStudentClass(value);
  };

  const onChangeClassModality = (value) => {
    setClassModality(value);
  };

  const onChangeClassShift = (value) => {
    setClassShift(value);
  };

  const onChangeClassLetter = (value) => {
    setClassLetter(value);
  };

  const onChangeSchoolEnrollmentStatus = (value) => {
    setStudentEnrollmentStatus(value);
  };

  const onChangeSchoolBondResult = (value) => {
    setSchoolBondResult(value);
  };

  const onChangeCourseCompletionAverage = (event) => {
    setCourseCompletionAverage(event.target.value);
  };

  useEffect(() => {
    getAcademicYears();
    getSchools();
    getClassModalities();
    getClassLetters();
    getStudentEnrollmentStatus();
    getSchoolBondResults();
  }, []);

  useEffect(() => {
    if (isLoaded && academicYear?.value) {
      setSchool("");
      setCourse("");
      setStudentClass("");
    }
  }, [academicYear]);

  useEffect(() => {
    if (isLoaded && school?.value) {
      getCourses();
      setCourse("");
      setStudentClass("");
    }
  }, [school]);

  useEffect(() => {
    if (isLoaded && course?.value) {
      getClasses();
      setStudentClass("");
    }
  }, [course]);

  useEffect(() => {
    if (isLoaded && classModality?.value) {
      getClassShifts();
      setClassShift("");
    }
  }, [classModality]);

  useEffect(() => {
    if (isLoaded && classLetter && classShift) {
      let courseClassName = course?.className;
      let studentClassNumber = studentClass.label?.split("ª")[0];
      let classLetterLabel = classLetter.label;
      let classShiftLetter = classShift.label[0];

      setClassFullName(
        `${courseClassName}${studentClassNumber}${classLetterLabel}${classShiftLetter}`
      );
    }
  }, [classLetter, classShift]);

  useEffect(() => {
    if (isOpen) {
      setAcademicYear(
        academicYearOptions.filter(
          (i) =>
            i.value === schoolBondRequestsData[index].academicYearBonded?.id ||
            i.value === schoolBondRequestsData[index].academicYearBonded?.value
        )[0]
      );
      setSchool(
        schoolOptions.filter(
          (i) =>
            i.value === schoolBondRequestsData[index].schoolBonded?.id ||
            i.value === schoolBondRequestsData[index].schoolBonded?.value
        )[0]
      );
      setClassModality(
        classModalityOptions.filter(
          (i) =>
            i.value === schoolBondRequestsData[index].classModalityBonded?.id ||
            i.value === schoolBondRequestsData[index].classModalityBonded?.value
        )[0]
      );
      setClassShift(
        classShiftOptions.filter(
          (i) =>
            i.value === schoolBondRequestsData[index].classShiftBonded?.id ||
            i.value === schoolBondRequestsData[index].classShiftBonded?.value
        )[0]
      );
      setClassLetter(
        classLetterOptions.filter(
          (i) =>
            i.value === schoolBondRequestsData[index].classLetterBonded?.id ||
            i.value === schoolBondRequestsData[index].classLetterBonded?.value
        )[0]
      );
      setClassFullName(schoolBondRequestsData[index].classFullName);
      setStudentEnrollmentStatus(
        studentEnrollmentStatusOptions.filter(
          (i) =>
            i.value ===
              schoolBondRequestsData[index].studentEnrollmentStatusBonded?.id ||
            i.value ===
              schoolBondRequestsData[index].studentEnrollmentStatusBonded?.value
        )[0]
      );
      setCourseCompletionAverage(
        schoolBondRequestsData[index].courseCompletionAverage
      );
      setHasLegalEquivalence(schoolBondRequestsData[index].hasLegalEquivalence);
      setHasLegalProof(schoolBondRequestsData[index].hasLegalProof);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      if (school?.value) {
        getCourses();
      }
    }
  }, [school]);

  useEffect(() => {
    if (isOpen) {
      if (!isLoaded && courseOptions?.length > 0) {
        setCourse(
          courseOptions.filter(
            (i) =>
              i.value === schoolBondRequestsData[index].courseBonded?.id ||
              i.value === schoolBondRequestsData[index].courseBonded?.value
          )[0]
        );
      }
    }
  }, [courseOptions]);

  useEffect(() => {
    if (isOpen) {
      if (!isLoaded && course?.value) {
        getClasses();
      }
    }
  }, [course]);

  useEffect(() => {
    if (isOpen) {
      if (!isLoaded && classModality?.value) {
        getClassShifts();
      }
    }
  }, [classModality]);

  useEffect(() => {
    if (isOpen) {
      if (!isLoaded && classShiftOptions?.length > 0) {
        setClassShift(
          classShiftOptions.filter(
            (i) =>
              i.value === schoolBondRequestsData[index].classShiftBonded?.id ||
              i.value === schoolBondRequestsData[index].classShiftBonded?.value
          )[0]
        );
      }
    }
  }, [classShiftOptions]);

  useEffect(() => {
    if (isOpen) {
      if (!isLoaded && classOptions?.length > 0) {
        setStudentClass(
          classOptions.filter(
            (i) =>
              i.value === schoolBondRequestsData[index].classBonded?.id ||
              i.value === schoolBondRequestsData[index].classBonded?.value
          )[0]
        );
        setIsLoaded(true);
      }
    }
  }, [classOptions]);

  useEffect(() => {
    if (studentEnrollmentStatus?.value === 2) {
      setSchoolBondResult(
        schoolBondResultOptions.filter((item) => item.value === 2)[0]
      );
      setSchoolBondResultIsDisabled(true);
    } else if (
      studentEnrollmentStatus?.value === 3 ||
      studentEnrollmentStatus?.value === 4
    ) {
      setSchoolBondResult(null);
      setSchoolBondResultIsDisabled(true);
    } else {
      setSchoolBondResult(
        schoolBondResultOptions.filter(
          (i) =>
            i.value ===
              schoolBondRequestsData[index].schoolBondResultBonded?.id ||
            i.value ===
              schoolBondRequestsData[index].schoolBondResultBonded?.value
        )[0]
      );
      setSchoolBondResultIsDisabled(false);
    }
  }, [studentEnrollmentStatus]);

  const checkAverage = (input) => {
    let string = input.replace(",", ".");
    let number = parseFloat(string);

    if (!isNaN(number) && number >= 10 && number <= 20) {
      if ((number * 100) % 1 === 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const handleSchoolBondRequests = () => {
    if (
      !academicYear?.value ||
      !school?.value ||
      !course?.value ||
      !studentClass?.value ||
      !classModality?.value ||
      !classShift?.value ||
      !classLetter?.value ||
      !studentEnrollmentStatus?.value
    ) {
      addToast({ title: REQUIRED_FIELD, status: "error" });
    } else if (
      (studentClass?.value === 18 || studentClass?.value === 22) &&
      schoolBondResult?.value === 1 &&
      !courseCompletionAverage
    ) {
      addToast({ title: REQUIRED_FIELD, status: "error" });
    } else if (
      courseCompletionAverage &&
      checkAverage(courseCompletionAverage)
    ) {
      addToast({
        title:
          "Valor da média de conclusão de curso deve estar estar compreendido entre 10,00 e 20,00",
        status: "error",
      });
    } else {
      const updateSchoolBondRequest = schoolBondRequests.map((obj, i) =>
        i === index
          ? {
              ...obj,
              academicYearBondedId: academicYear?.value,
              schoolBondedId: school?.value,
              courseBondedId: course?.value,
              courseTechnicalBondedId: course.courseTechnicalId,
              courseEducationalSubsystemBondedId: course.educationalSubsystemId,
              courseEducationalCycleBondedId: course.educationalCycleId,
              studentEnrollmentStatusBondedId: studentEnrollmentStatus?.value,
              classBondedId: studentClass?.value,
              classModalityBondedId: classModality?.value,
              classShiftBondedId: classShift?.value,
              classLetterBondedId: classLetter?.value,
              classFullName: classFullName,
              schoolBondResultBondedId: schoolBondResult?.value
                ? schoolBondResult?.value
                : null,
              courseCompletionAverage: courseCompletionAverage
                ? courseCompletionAverage.replace(",", ".")
                : null,
              hasLegalEquivalence: hasLegalEquivalence,
              hasLegalProof: hasLegalProof,
            }
          : obj
      );

      const updateSchoolBondRequestData = schoolBondRequestsData.map((obj, i) =>
        i === index
          ? {
              academicYearBonded: academicYear,
              schoolBonded: school,
              courseBonded: course,
              courseTechnicalBonded: {
                id: course.courseTechnicalId,
                name: course.courseTechnicalName,
              },
              courseEducationalSubsystemBonded: {
                id: course.educationalSubsystemId,
                name: course.educationalSubsystemName,
              },
              courseEducationalCycleBonded: {
                id: course.educationalCycleId,
                name: course.educationalCycleName,
              },
              studentEnrollmentStatusBonded: studentEnrollmentStatus,
              classBonded: studentClass,
              classModalityBonded: classModality,
              classShiftBonded: classShift,
              classLetterBonded: classLetter,
              classFullName: classFullName,
              schoolBondResultBonded: schoolBondResult
                ? schoolBondResult
                : null,
              courseCompletionAverage: courseCompletionAverage
                ? courseCompletionAverage.replace(",", ".")
                : null,
            }
          : obj
      );

      setSchoolBondRequests(updateSchoolBondRequest);
      setSchoolBondRequestsData(updateSchoolBondRequestData);

      onClose();
    }
  };

  return (
    <>
      <Button variant="secondary" onClick={onOpen}>
        Editar
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar Dados Escolares</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoaded && (
              <Stack gap={5}>
                <Box>
                  <Label title="Ano Lectivo" isRequired />
                  <Select
                    placeholder="Seleccionar"
                    options={academicYearOptions}
                    onChange={onChangeAcademicYear}
                    value={academicYear || ""}
                    isDisabled={true}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                </Box>
                <Box>
                  <Label title="Escola" isRequired />
                  <Select
                    placeholder="Seleccionar"
                    options={schoolOptions}
                    onChange={onChangeSchool}
                    value={school || ""}
                    isDisabled={true}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                </Box>
                <Box>
                  <Label title="Curso" isRequired />
                  <Select
                    placeholder="Seleccionar"
                    options={courseOptions}
                    onChange={onChangeCourse}
                    value={course || ""}
                    isDisabled={true}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                </Box>
                <Box>
                  <Label title="Área de Formação do INFQE" isRequired />
                  <Input
                    value={course?.value ? course?.courseTechnicalName : ""}
                    isDisabled={true}
                  />
                </Box>
                <Box>
                  <Label title="Subsistema de Ensino" isRequired />
                  <Input
                    value={
                      course?.value ? course?.educationalSubsystemName : ""
                    }
                    isDisabled
                  />
                </Box>
                <Box>
                  <Label title="Ciclo de Ensino" isRequired />
                  <Input
                    value={course?.value ? course?.educationalCycleName : ""}
                    isDisabled
                  />
                </Box>
                <Box>
                  <Label title="Classe" isRequired />
                  <Select
                    placeholder="Seleccionar"
                    options={classOptions}
                    onChange={onChangeClass}
                    value={studentClass || ""}
                    isDisabled={true}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                  {academicYear?.value && !academicYear.isBaseYear && (
                    <Box mt={2}>
                      <Stack spacing={2}>
                        <Text fontSize="sm" fontWeight="700">
                          Caso queira matricular o aluno numa outra classe,
                          indique o motivo:
                        </Text>
                        <Checkbox
                          isDisabled={true}
                          isChecked={hasLegalEquivalence}
                        >
                          <Text fontSize="sm">{HAS_LEGAL_EQUIVALENCE}</Text>
                        </Checkbox>
                        <Checkbox isDisabled={true} isChecked={hasLegalProof}>
                          <Text fontSize="sm">{HAS_LEGAL_PROOF}</Text>
                        </Checkbox>
                      </Stack>
                    </Box>
                  )}
                </Box>
                <Box>
                  <Label title="Modalidade" isRequired />
                  <Select
                    placeholder="Seleccionar"
                    options={classModalityOptions}
                    onChange={onChangeClassModality}
                    value={classModality || ""}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                </Box>
                <Box>
                  <Label title="Turno" isRequired />
                  <Select
                    placeholder="Seleccionar"
                    options={classShiftOptions}
                    onChange={onChangeClassShift}
                    value={classShift || ""}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                </Box>
                <Box>
                  <Label title="Letra da Turma" isRequired />
                  <Select
                    placeholder="Seleccionar"
                    options={classLetterOptions}
                    onChange={onChangeClassLetter}
                    value={classLetter || ""}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                </Box>
                <Box>
                  <Label title="Turma" isRequired />
                  <Input value={classFullName} isDisabled />
                </Box>
                <Box>
                  <Label title="Estado da matrícula" isRequired />
                  <Select
                    placeholder="Seleccionar"
                    options={studentEnrollmentStatusOptions}
                    onChange={onChangeSchoolEnrollmentStatus}
                    value={studentEnrollmentStatus || ""}
                    styles={selectStyles}
                    noOptionsMessage={NO_OPTIONS_MESSAGE}
                  />
                </Box>
                {id && (
                  <>
                    <Box>
                      <Label title="Resultado" />
                      <Select
                        placeholder="Seleccionar"
                        options={schoolBondResultOptions}
                        onChange={onChangeSchoolBondResult}
                        value={schoolBondResult || ""}
                        styles={selectStyles}
                        noOptionsMessage={NO_OPTIONS_MESSAGE}
                        isDisabled={schoolBondResultIsDisabled}
                      />
                    </Box>
                    {(studentClass?.value === 18 ||
                      studentClass?.value === 22) &&
                      schoolBondResult?.value === 1 && (
                        <Box>
                          <Label
                            title="Média de conclusão de curso"
                            isRequired
                          />
                          <Input
                            value={courseCompletionAverage}
                            onChange={onChangeCourseCompletionAverage}
                            isDisabled={schoolBondResult?.value !== 1}
                          />
                          <Text fontSize="sm" color="#4A5568" mt={1}>
                            Valor deve estar compreendido entre 10,00 e 20,00
                          </Text>
                        </Box>
                      )}
                  </>
                )}
              </Stack>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" onClick={onClose} mr={3}>
              Cancelar
            </Button>
            <Button onClick={handleSchoolBondRequests}>Guardar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default EditEnrollmentModal;
